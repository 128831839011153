#place-button{
  position: relative;
  top: 0;
  left: calc(90% - 50px);
  width: 100px;
  height: 35px;
  /* left: 100%; */
  display: none;
  /* margin-bottom: 70px !important; */
}

#VRButton{
  margin-bottom: 70px !important;
}
.ar__canvas{
  visibility: hidden;
  height: 0.01px !important;
}
#container{
  visibility: visible;
  position: absolute;
}
#ARButton{
  left: 0 !important;
  top: 0 !important;
  bottom: unset !important;
  display: none;
}