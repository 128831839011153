@media only screen and (max-width: 768px) {
    input[type="range"] {
        position: relative;
        left: 0px;
        width: 5em;
    }

    #player-timer-col {
        position: relative;
        left: 50px;
    }
}