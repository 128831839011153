#checkchrome_main{
    background-color: #020202;
    height:100vh;
}

.text-holotch{
    color: #C399F8 !important;
    font-family: Roboto;
  }

.centralize {
    margin: auto;
    width: 70%;
    padding: 10px;
    text-align: center;
    margin-top: 100px;
}

.chrome-check{
    background-color: #6b04f1 !important;
    background: #6b04f1 !important;
    color: #FFFFFF !important;
    border-radius:50px;
}
.roboto-text{
    font-family: Roboto !important;
    font-size: 24px !important;
}