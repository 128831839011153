@import './main.index.css';
/* ******************************************************
                  Bootstrap Icons                       * 
******************************************************* */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

.text-holotch{
  color: #C399F8 !important;
}
.modal-backdrop {
  z-index: 99;
}
.clickable{
  cursor: pointer;
}
.modal-content {
  background-color: unset;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212;
}

#play-pause{
  position: relative;
  margin: 0;
  right: 20px;
}

#left-timeline{
  position: relative;
  margin: 0;
  right: 60px;
}

#right-timeline{
  position: relative;
  margin: 0;
  left: 50px;
}

#player_bar{
  background: rgba(18, 18, 18, 0.5);
  border-radius: 8px;
  height: 31px;
  position: relative;
  top: 8px; /*25px*/
}

#expander{
  position: relative;
  margin: 0;
  left: 25px;
}



input[type="range"]::-webkit-slider-thumb {
  border-radius: 50%;
  background: white;
  cursor: ew-resize;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 0;
  height: 0;
}


input[type="range"]::-webkit-slider-thumb {
  height: 12px;
  width: 12px;
}

input[type="range"] {
  position: relative;
  /* left: -120px;
  width: 127%; */
}

#player-timer-col {
  position: relative;
  left: 25px;
}

@media (max-width: 768px) {
  input[type="range"] {
      position: relative;
      left: 0;
      width: 100%;
  }

  #player-timer-col {
      position: relative;
      left: 50px;
  }
}



body {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.no-decor {
  text-decoration: none;
}

/* ******************************************************
   Bootstrap Over-rides         &nbsp;                         * 
******************************************************* */
.text-danger {
  color: #EA4E3D !important;
}

.text-getlike {
  color: #C399F8 !important;
}

.bg-danger {
  background: #EA4E3D !important;
}

.border-danger {
  color: #EA4E3D !important;
}

.floating-up-down {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

#ar-section {
  background-color: none;
  background: none;
}

.live-dot{
  font-size: 15px;
  color: #D14636;

}

.expand_btn{
margin-left: 20px;
}
@media (max-width: 992px) {
  .expand_btn{
    margin-left: -10px;
  }
}

.userContainer{
  display: flex;
  align-items: center;
  justify-content: right;
}
b
.userImage{
  flex-basis: 20%
}
.userNameText{
  font-size: 20px;
  padding-left: 10px;
  color: #b7b7b7;
  font-size: 17px;
}

.signButtonContainer{
  padding-left: 20px;
}

.signButton{
  background-color: #393939;
  border: 0.1rem outset rgb(247, 192, 255);
}
.signButton:hover {
  background: rgba(247, 192, 255, 0.372);
}

.hologramsPagination{
  padding-left: 160px;
}

.yourUploads{
  padding-left: 200px;
}

.page-item {
  background-color: #ffffff;
  color: white;
}
.activePage {
  background-color: #9377b3;
}

.profilePic{
  align-items: left;
}
.fa-trash-alt{
  color:rgb(202, 68, 68)
}

.rowAlignRight{
  align-items: right;
}

.titleContainer {
  display: flex;
  flex-wrap: wrap;
}

.fa-eye-slash{
  color:rgb(167, 167, 167)
}

.clickableText{
  padding: 0;
  border: none;
  background: none;
  color: #bf9aea

}
.bulletList{
  text-align: left;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}